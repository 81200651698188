import React from 'react';

interface UtilizationBarProps {
  percentage: number;
}

const UtilizationBar: React.FC<UtilizationBarProps> = ({ percentage }) => {
  return (
    <div className="utilization-bar">
      <div className="utilization-fill" style={{ width: `${percentage}%` }}></div>
      <span className="utilization-text">{percentage.toFixed(2)}% Utilized</span>
    </div>
  );
};

export default UtilizationBar;
