import { initializeApp } from "firebase/app";
import { get, getDatabase, onValue, ref, set, push, serverTimestamp } from "firebase/database";
import { getAnalytics, logEvent } from "firebase/analytics";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
};

const app = initializeApp(firebaseConfig);
const db = getDatabase(app);

const analytics = getAnalytics(app);

export { analytics, logEvent };

export const initializeCanvas = async () => {
  const canvasRef = ref(db, 'canvas');
  const snapshot = await get(canvasRef);
  
  if (!snapshot.exists() || !isValidCanvasData(snapshot.val())) {
    const initialCanvas = Array(100).fill(null).map(() => Array(100).fill('#FFFFFF'));
    await set(canvasRef, initialCanvas);
    return initialCanvas;
  } else {
    return snapshot.val();
  }
};

export const subscribeToUpdates = (callback: (data: string[][]) => void) => {
  const canvasRef = ref(db, 'canvas');

  onValue(canvasRef, (snapshot) => {
    const data = snapshot.val();
    if (isValidCanvasData(data)) {
      callback(data);
    } else {
      console.error('Invalid canvas data structure:', data);
      const defaultCanvas = Array(100).fill(null).map(() => Array(100).fill('#FFFFFF'));
      set(canvasRef, defaultCanvas);
      callback(defaultCanvas);
    }
  }, (error) => {
    console.error('Error subscribing to updates:', error);
  });
};

function isValidCanvasData(data: any): data is string[][] {
  return (
    data &&
    Array.isArray(data) &&
    data.every(row => Array.isArray(row) && row.every(color => typeof color === 'string'))
  );
}

let updateCounter = 0;
const UPDATE_THRESHOLD = 100; // Check utilization every 100 updates

let pendingUpdates: { [key: string]: string } = {};
let updateTimeout: NodeJS.Timeout | null = null;

export const updatePixel = async (x: number, y: number, color: string) => {
  const key = `${x},${y}`;
  pendingUpdates[key] = color;

  if (updateTimeout) {
    clearTimeout(updateTimeout);
  }

  updateTimeout = setTimeout(async () => {
    const canvasRef = ref(db, 'canvas');
    const snapshot = await get(canvasRef);
    
    if (!snapshot.exists()) {
      console.error('Canvas does not exist');
      return null;
    }

    let canvas = snapshot.val();
    
    for (const [key, color] of Object.entries(pendingUpdates)) {
      const [x, y] = key.split(',').map(Number);
      canvas[y][x] = color;
    }

    updateCounter += Object.keys(pendingUpdates).length;

    if (updateCounter >= UPDATE_THRESHOLD) {
      const utilization = calculateCanvasUtilization(canvas);
      if (utilization > 90) {
        canvas = await expandCanvas();
      }
      updateCounter = 0;
    }

    await set(canvasRef, canvas);
    for (const [key, color] of Object.entries(pendingUpdates)) {
      const [x, y] = key.split(',').map(Number);
      await recordChange(x, y, color);
    }
    
    pendingUpdates = {};
    updateTimeout = null;
  }, 1000);

  return null;
};

export const expandCanvas = async () => {
  const canvasRef = ref(db, 'canvas');
  const snapshot = await get(canvasRef);
  
  if (!snapshot.exists()) {
    console.error('Canvas does not exist');
    return null;
  }

  let canvas = snapshot.val();
  const currentSize = canvas.length;
  const newSize = currentSize * 2;

  const newCanvas = Array(newSize).fill(null).map(() => Array(newSize).fill('#FFFFFF'));

  // Copy the existing canvas to the top-left quadrant of the new canvas
  for (let y = 0; y < currentSize; y++) {
    for (let x = 0; x < currentSize; x++) {
      newCanvas[y][x] = canvas[y][x] || '#FFFFFF'; // Use '#FFFFFF' if the value is undefined
    }
  }

  await set(canvasRef, newCanvas);
  return newCanvas;
};

export const recordChange = async (x: number, y: number, color: string) => {
  const changeRef = ref(db, 'changes');
  const newChangeRef = push(changeRef);
  await set(newChangeRef, {
    x,
    y,
    color,
    timestamp: serverTimestamp()
  });
};

export const getChanges = async () => {
  const changesRef = ref(db, 'changes');
  const snapshot = await get(changesRef);
  if (snapshot.exists()) {
    const changesData = snapshot.val();
    return Object.values(changesData).sort((a: any, b: any) => a.timestamp - b.timestamp);
  }
  return [];
};

export { db };

export const calculateCanvasUtilization = (canvas: string[][]): number => {
  const totalPixels = canvas.length * canvas[0].length;
  const usedPixels = canvas.flat().filter(color => color !== '#FFFFFF').length;
  return (usedPixels / totalPixels) * 100;
};