import React, { useRef, useEffect, useState, useCallback } from 'react';
import { getChanges } from '../firebase';
import { analytics, logEvent } from '../firebase';

interface CanvasProps {
  data: string[][];
  onPixelClick: (x: number, y: number) => Promise<string[][] | void>;
  isPlaying: boolean;
  setIsPlaying: React.Dispatch<React.SetStateAction<boolean>>;
  selectedColor: string;
}

const Canvas: React.FC<CanvasProps> = ({ data, onPixelClick, isPlaying, setIsPlaying, selectedColor }) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const [error, setError] = useState<string | null>(null);
  const [changes, setChanges] = useState<any[]>([]);
  const [currentFrame, setCurrentFrame] = useState(0);
  const [isDrawing, setIsDrawing] = useState(false);
  const lastPixelRef = useRef<{ x: number; y: number } | null>(null);
  const [frameRate, setFrameRate] = useState(20); // Default to 20 fps

  const [isPanning, setIsPanning] = useState(false);
  const [panPosition, setPanPosition] = useState({ x: 0, y: 0 });
  const lastPanPosition = useRef({ x: 0, y: 0 });
  const canvasContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const fetchChanges = async () => {
      const fetchedChanges = await getChanges();
      setChanges(fetchedChanges);
    };
    fetchChanges();
  }, []);

  const renderCanvas = useCallback((canvasData: string[][]) => {
    const canvas = canvasRef.current;
    if (!canvas) {
      setError('Canvas element not found');
      return;
    }

    const ctx = canvas.getContext('2d');
    if (!ctx) {
      setError('Unable to get 2D context');
      return;
    }

    try {
      const pixelSize = 10;
      const delimiterSize = 1;
      canvas.width = canvasData[0].length * (pixelSize + delimiterSize) + delimiterSize;
      canvas.height = canvasData.length * (pixelSize + delimiterSize) + delimiterSize;
      
      ctx.fillStyle = '#E0E0E0';
      ctx.fillRect(0, 0, canvas.width, canvas.height);

      canvasData.forEach((row, y) => {
        row.forEach((color, x) => {
          ctx.fillStyle = color;
          ctx.fillRect(
            x * (pixelSize + delimiterSize) + delimiterSize,
            y * (pixelSize + delimiterSize) + delimiterSize,
            pixelSize,
            pixelSize
          );
        });
      });
    } catch (err: any) {
      setError(`Error rendering canvas: ${err.message}`);
    }
  }, []);

  useEffect(() => {
    renderCanvas(data);
    if (canvasContainerRef.current) {
      canvasContainerRef.current.style.transform = `translate(${panPosition.x}px, ${panPosition.y}px)`;
    }
  }, [data, renderCanvas, panPosition]);

  const resetCanvas = useCallback(() => {
    const canvas = canvasRef.current;
    if (!canvas) return;

    const ctx = canvas.getContext('2d');
    if (!ctx) return;

    ctx.fillStyle = '#FFFFFF';
    ctx.fillRect(0, 0, canvas.width, canvas.height);
  }, []);
  
  useEffect(() => {
    

    if (isPlaying && currentFrame === 0) {
      resetCanvas();
      logEvent(analytics, 'playback_started', { total_frames: changes.length });
    }

    if (isPlaying && currentFrame < changes.length) {
      const timer = setTimeout(() => {
        const change = changes[currentFrame];
        const canvas = canvasRef.current;
        if (canvas) {
          const ctx = canvas.getContext('2d');
          if (ctx) {
            const pixelSize = 10;
            const delimiterSize = 1;
            ctx.fillStyle = change.color;
            ctx.fillRect(
              change.x * (pixelSize + delimiterSize) + delimiterSize,
              change.y * (pixelSize + delimiterSize) + delimiterSize,
              pixelSize,
              pixelSize
            );
          }
        }
        setCurrentFrame(currentFrame + 1);
      }, 1000 / frameRate);
      return () => clearTimeout(timer);
    } else if (currentFrame >= changes.length) {
      setIsPlaying(false);
      setCurrentFrame(0);
      renderCanvas(data);
      logEvent(analytics, 'playback_finished', { total_frames: changes.length });
    }
  }, [isPlaying, currentFrame, changes, data, renderCanvas, resetCanvas, frameRate, setIsPlaying]);

  const handlePlayClick = () => {
    setIsPlaying(true);
    setCurrentFrame(0);
    resetCanvas();
  };


  const handleMouseDown = (event: React.MouseEvent<HTMLCanvasElement>) => {
    if (isPlaying) return;
    setIsDrawing(true);
    handlePixelChange(event);
  };

  const handleMouseMove = (event: React.MouseEvent<HTMLCanvasElement>) => {
    if (isPlaying || !isDrawing) return;
    handlePixelChange(event);
  };

  const handleMouseUp = () => {
    setIsDrawing(false);
    lastPixelRef.current = null;
  };

  const handlePixelChange = useCallback((event: React.MouseEvent<HTMLCanvasElement>) => {
    const canvas = canvasRef.current;
    if (!canvas) return;

    const rect = canvas.getBoundingClientRect();
    const pixelSize = 11; // 10px for the pixel + 1px for the delimiter
    const x = Math.floor((event.clientX - rect.left) / pixelSize);
    const y = Math.floor((event.clientY - rect.top) / pixelSize);

    if (lastPixelRef.current?.x === x && lastPixelRef.current?.y === y) return;

    lastPixelRef.current = { x, y };

    const ctx = canvas.getContext('2d');
    if (ctx) {
      ctx.fillStyle = selectedColor;
      ctx.fillRect(x * pixelSize + 1, y * pixelSize + 1, pixelSize - 1, pixelSize - 1);
    }

    onPixelClick(x, y);
    logEvent(analytics, 'pixel_changed', { x, y, color: selectedColor });
  }, [onPixelClick, selectedColor]);

  const handleFrameRateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFrameRate(Number(event.target.value));
  };

  const handlePanStart = useCallback((event: React.MouseEvent<HTMLDivElement | HTMLCanvasElement>) => {
    if ((event.button === 1) || (event.button === 0 && event.ctrlKey)) {
      setIsPanning(true);
      lastPanPosition.current = { x: event.clientX, y: event.clientY };
    }
  }, []);

  const handlePanMove = useCallback((event: React.MouseEvent<HTMLDivElement | HTMLCanvasElement>) => {
    if (isPanning) {
      const deltaX = event.clientX - lastPanPosition.current.x;
      const deltaY = event.clientY - lastPanPosition.current.y;
      setPanPosition(prev => ({ x: prev.x + deltaX, y: prev.y + deltaY }));
      lastPanPosition.current = { x: event.clientX, y: event.clientY };
    }
  }, [isPanning]);

  const handlePanEnd = useCallback(() => {
    setIsPanning(false);
  }, []);

  const handleTouchStart = useCallback((event: React.TouchEvent<HTMLDivElement>) => {
    if (event.touches.length === 1) {
      setIsPanning(true);
      lastPanPosition.current = { x: event.touches[0].clientX, y: event.touches[0].clientY };
    }
  }, []);

  const handleTouchMove = useCallback((event: React.TouchEvent<HTMLDivElement>) => {
    if (isPanning && event.touches.length === 1) {
      const deltaX = event.touches[0].clientX - lastPanPosition.current.x;
      const deltaY = event.touches[0].clientY - lastPanPosition.current.y;
      setPanPosition(prev => ({ x: prev.x + deltaX, y: prev.y + deltaY }));
      lastPanPosition.current = { x: event.touches[0].clientX, y: event.touches[0].clientY };
    }
  }, [isPanning]);

  const handleTouchEnd = useCallback(() => {
    setIsPanning(false);
  }, []);

  return (
    <div className="canvas-container">
      {error ? (
        <div>Error: {error}</div>
      ) : (
        <>
          <div className="pan-instructions">
            To pan: Hold Ctrl (or Cmd on Mac) and drag, or use middle mouse button, or use one finger drag on mobile
          </div>
          <div
            ref={canvasContainerRef}
            style={{ cursor: isPanning ? 'grabbing' : 'grab', touchAction: 'none' }}
            onMouseDown={handlePanStart}
            onMouseMove={handlePanMove}
            onMouseUp={handlePanEnd}
            onMouseLeave={handlePanEnd}
            onTouchStart={handleTouchStart}
            onTouchMove={handleTouchMove}
            onTouchEnd={handleTouchEnd}
          >
            <canvas
              ref={canvasRef}
              onMouseDown={(e) => {
                if (!e.ctrlKey && e.button !== 1) handleMouseDown(e);
              }}
              onMouseMove={(e) => {
                if (isPanning) handlePanMove(e);
                else handleMouseMove(e);
              }}
              onMouseUp={(e) => {
                if (isPanning) handlePanEnd();
                else handleMouseUp();
              }}
              onMouseLeave={handleMouseUp}
            />
          </div>
          <div className="playback-controls">
            <button onClick={handlePlayClick} disabled={isPlaying}>
              {isPlaying ? 'Playing...' : 'Play History'}
            </button>
            <div className="frame-rate-control">
              <label htmlFor="frameRate">Frame Rate: {frameRate} fps</label>
              <input
                type="range"
                id="frameRate"
                min="1"
                max="60"
                value={frameRate}
                onChange={handleFrameRateChange}
              />
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default Canvas;