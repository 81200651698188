import React from 'react';

interface ColorPickerProps {
  onColorSelect: (color: string) => void;
}

const ColorPicker: React.FC<ColorPickerProps> = ({ onColorSelect }) => {
  const colors = [
    '#FF0000', // Red
    '#FF4500', // Orange Red
    '#FFA500', // Orange
    '#FFD700', // Gold
    '#FFFF00', // Yellow
    '#9ACD32', // Yellow Green
    '#008000', // Green
    '#00CED1', // Dark Turquoise
    '#1E90FF', // Dodger Blue
    '#0000FF', // Blue
    '#8A2BE2', // Blue Violet
    '#800080', // Purple
    '#FF1493', // Deep Pink
    '#C71585', // Medium Violet Red
    '#8B4513', // Saddle Brown
    '#FFFFFF', // White
    '#808080', // Gray
    '#000000', // Black
  ];

  return (
    <div className="color-picker">
      {colors.map((color) => (
        <button
          key={color}
          style={{ backgroundColor: color }}
          onClick={() => onColorSelect(color)}
          aria-label={`Select ${color} color`}
        />
      ))}
    </div>
  );
};

export default ColorPicker;