import React, { useEffect } from 'react';
import { analytics, logEvent } from '../firebase';

declare global {
  namespace JSX {
    interface IntrinsicElements {
      'stripe-buy-button': React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement> & {
        'buy-button-id': string;
        'publishable-key': string;
      };
    }
  }
}


interface DonateButtonProps {
  className?: string;
}

const DonateButton: React.FC<DonateButtonProps> = ({ className }) => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://js.stripe.com/v3/buy-button.js';
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const handleDonateClick = () => {
    logEvent(analytics, 'donation_button_clicked');
  };

  return (
    <div className={`donate-button-container ${className || ''}`} onClick={handleDonateClick}>
      <stripe-buy-button
  buy-button-id="buy_btn_1PwnQSL3FjjIN4vniVDwW3rW"
  publishable-key="pk_live_51Oia0ZL3FjjIN4vnCaOdxZMUHhcEqJcQTBFJdA8nr5pVH6vCf9LfcE4o5NpBjWJwTe07CtlV0hYXBzNtOIExb9FM004tGNeSVx"
>
</stripe-buy-button>
    </div>
  );
};

export default DonateButton;