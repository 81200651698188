import React, { useState, useEffect } from 'react';
import Canvas from './components/Canvas';
import ColorPicker from './components/ColorPicker';
import DonateButton from './components/DonateButton';
import { updatePixel, subscribeToUpdates, initializeCanvas } from './firebase';
import './App.css';

import UtilizationBar from './components/UtilizationBar';

function App() {
  const [selectedColor, setSelectedColor] = useState('#000000');
  const [canvasData, setCanvasData] = useState<string[][]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isPlaying, setIsPlaying] = useState(false);
  const [utilization, setUtilization] = useState(0);

  const calculateUtilization = (canvasData: string[][]) => {
    const totalPixels = canvasData.length * canvasData[0].length;
    const usedPixels = canvasData.flat().filter(color => color !== '#FFFFFF').length;
    return (usedPixels / totalPixels) * 100;
  };

  useEffect(() => {
    const setupCanvas = async () => {
      try {
        const initialCanvas = await initializeCanvas();
        setCanvasData(initialCanvas);
        setUtilization(calculateUtilization(initialCanvas));
        
        subscribeToUpdates((newCanvasData) => {
          setCanvasData(newCanvasData);
          setUtilization(calculateUtilization(newCanvasData));
          setIsLoading(false);
        });
      } catch (error) {
        console.error('Error setting up canvas:', error);
        setIsLoading(false);
      }
    };

    setupCanvas();
  }, []);

  const handlePixelUpdate = async (x: number, y: number) => {
    if (isPlaying) return;
    await updatePixel(x, y, selectedColor);
  };

  if (isLoading) {
    return <div className="loading">Loading...</div>;
  }

  return (
    <div className="App">
      <h1>The Internet Painting</h1>
      <UtilizationBar percentage={utilization} />
      <div className="canvas-container">
        <ColorPicker onColorSelect={setSelectedColor} />
        {canvasData.length > 0 && (
          <Canvas
            data={canvasData}
            onPixelClick={handlePixelUpdate}
            isPlaying={isPlaying}
            setIsPlaying={setIsPlaying}
            selectedColor={selectedColor}
          />
        )}
      </div>
      <DonateButton className="main-donate-button" />
    </div>
  );
}

export default App;